.grid {
  background: rgba(0, 0, 0, 0.5);
}
.grid :hover {
  transition: transform 0.15s ease-in-out;
  /* background: rgba(241, 11, 11, 0.975); */
  transform: scale3d(1.05, 1.05, 1);
  box-shadow: 0.5px 0.5px 10px 0.5px #6df8fe;
}
.box {
  background: rgba(70, 70, 70, 0.8);
  border-radius: 10px;
  border: 1px solid white !important;
  font-family: Jedi;
  color: black;
}

.custom-scroll::-webkit-scrollbar {
  width: 10px !important;
  height: 5px !important;
}
.custom-scroll::-webkit-scrollbar-track {
  background: #00304773;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
