/* body{
  display:flex;
  align-items:center;
  justify-content:center;
  height:100vh;
  width:100vw;
} */
.swiper-container {
  position: relative;
  width: 90vw;
  height: 300px;
}
.swiper-container > .swiper-slide__content {
  position: absolute;
  top: 0;
}

.swiper-slide {
  /* height: 300px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  transform: scale(0.8);
}

.swiper-button-next {
  background-image: url("../../../assets/BreedingAssets/ArrowRight.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev {
  background-image: url("../../../assets/BreedingAssets/ArrowLeft.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}
.swiper-button-prev::after {
  display: none;
}
/* .swiper-slide__content {
  /* height: 300px; */
/* } */
