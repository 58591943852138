.custom-datepicker {
  width: 206px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* padding: 8px; */
  font-size: 14px;
  color: #333;
  background-color: "black";
  z-index: 1;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 33px !important;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.6rem !important;
}

.custom-datepicker-wrapper {
  display: flex; /* Use flexbox to align DatePicker and icon horizontally */
  align-items: center; /* Vertically center the elements */
  flex: row;
}

.custom-icon {
  /* Your icon styles here */
  /* For example, if you want to add some spacing between the DatePicker and the icon: */
  margin-left: 8px;
}
.custom-datepicker input {
  width: 100%;
  height: 32px;
  padding: 4px;
}

.custom-datepicker .react-datepicker__triangle {
  border-bottom-color: #ccc;
}

.custom-datepicker .react-datepicker__header {
  background-color: #f2f2f2;
  border-bottom: none;
}

.custom-datepicker .react-datepicker__day {
  color: #333;
}

.custom-datepicker .react-datepicker__day--selected {
  background-color: #337ab7;
  color: #fff;
}

.custom-datepicker .react-datepicker__day--disabled {
  color: #999;
}

.custom-datepicker .react-datepicker__day--keyboard-selected {
  background-color: #337ab7;
  color: #fff;
}

/*------------ Form --------------- */
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-file-upload input[type="file"] {
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.custom-file-upload input[type="file"] + label {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  background-color: linear-gradient(
    to right,
    #61a6aa,
    #465b87,
    #319197 100%,
    #319197 200%
  );
  border: none;
  border-radius: 8px;
  padding: 1rem 1.25rem;
  text-shadow: 0 1px 1px #333;
  transition: all 0.25s;
}

.custom-file-upload input[type="file"] + label:hover {
  background-position-x: 100%;
  transform: scale(1.1);
}

/* choose file */
.custom-file-input-campaign {
  display: inline-block;
  padding: 8px 12px;
  background-color: #f2f2f2;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-file-input-campaign:hover {
  background-color: #e6e6e6;
}

.custom-file-input-campaign:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3);
}
/* choose file */
.custom-file-input-traits {
  display: inline-block;
  padding: 8px 12px;
  background-color: #f2f2f2;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-file-input-traits:hover {
  background-color: #e6e6e6;
}

.custom-file-input-traits:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3);
}

.MuiInputBase-input
  .MuiInput-input
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: "14px";
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  /* background-color: #b51148; */
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white;
  /* border: 1px solid #b51148; */
  margin: 0.066rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #b51148;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.example-datepicker-class .react-datepicker__today-button {
  color: #b51148;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}

.error-message {
  color: #d32f2f;
  font-size: 13px;
  padding-left: "20px";
}

.error-message-date {
  color: #d32f2f;
  font-size: 12px;
  padding-left: "20px";
}
