.grid{
    background: rgba(0, 0, 0, 0.5);
}
.grid :hover {
    transition: transform 0.15s ease-in-out;
    /* background: rgba(241, 11, 11, 0.975); */
    transform: scale3d(1.05, 1.05, 1);
    box-shadow: 0.5px 0.5px 10px 0.5px #6DF8FE
  }
  .box {
    background: rgba(70, 70, 70, 0.8);
    border-radius: 10px;
    border: 1px solid white !important;
    font-family: Jedi;
    color: black;
}
